import React, { useState } from "react";
import useMediaQuery from "./UseMediaQuery";

export default function Results(props) {
  const isDesktop = useMediaQuery("(min-width: 960px)");

  let imageAttacker = `${process.env.PUBLIC_URL}/Attacker-images/${props.selectedUnitAttackerData.imageSource}`;
  let imageDefender = `${process.env.PUBLIC_URL}/Defender-images/${props.selectedUnitDefenderData.imageSource}`;
  let defeatedImage = "/misc-images/Vengir_Skull.png}";

  var attackerMaxHPToUse = props.selectedUnitAttackerData.maxHealth;
  var defenderMaxHPToUse = props.selectedUnitDefenderData.maxHealth;
  var posionReduceDamage = props.selectedUnitDefenderData.defence;

  var boostedDamageToUse = props.selectedUnitAttackerData.attack;
  var defenseBonus = props.selectedUnitDefenderData.default_Bonus;

  var defenseBonusTotal = props.selectedUnitDefenderData.defence * 1.5;
  var defenseCityWallTotal = props.selectedUnitDefenderData.defence * 2.0;

  var veteranBuff = 5;

  // veteran for offense
  if (props.selectedUnitAttackerData.isVeteran) {
    attackerMaxHPToUse = attackerMaxHPToUse + 5;
  }

  // veteran for defense
  if (props.selectedUnitDefenderData.isVeteran) {
    defenderMaxHPToUse = defenderMaxHPToUse + 5;
  }

  //  posion
  if (props.selectedUnitDefenderData.isPoisoned) {
    props.selectedUnitDefenderData.default_Bonus = 0.8;
  } else {
    props.selectedUnitDefenderData.default_Bonus = 1;
  }

  //  boosted
  if (props.selectedUnitAttackerData.isBoosted) {
    boostedDamageToUse = boostedDamageToUse + 0.5;
  }

  // defense bonuses
  if (
    props.selectedUnitDefenderData.defence_Bonus &&
    !props.selectedUnitDefenderData.cityWall
  ) {
    props.selectedUnitDefenderData.default_Bonus =
      props.selectedUnitDefenderData.default_Bonus * 1.5;
  } else if (props.selectedUnitDefenderData.isCityWall) {
    props.selectedUnitDefenderData.default_Bonus =
      props.selectedUnitDefenderData.default_Bonus * 4;
  }

  // reset button
  function resetButton() {
    props.resetData(props.isAttacker, props.defaultSelectedAttacker);
  }

  var defenceForce =
    props.selectedUnitDefenderData.defence *
    (props.selectedUnitDefenderData.inputHealth / defenderMaxHPToUse) *
    props.selectedUnitDefenderData.default_Bonus;

  var attackForce =
    boostedDamageToUse *
    (props.selectedUnitAttackerData.inputHealth / attackerMaxHPToUse);

  //  console.log(props.selectedUnitDefenderData.default_Bonus);
  //   removed * props.selectedUnitDefenderData.defence_Bonus; at the end of line 8
  var totalDamage = attackForce + defenceForce;

  // logic for offense defeating defender for retaliation damage

  var attackResult = Math.round(
    (attackForce / totalDamage) * boostedDamageToUse * 4.5
  );

  var defenceResult = Math.round(
    (defenceForce / totalDamage) * props.selectedUnitDefenderData.defence * 4.5
  );

  var defence_status_HP =
    props.selectedUnitDefenderData.inputHealth - attackResult;

  // if (props.defence_status_HP > 0) {

  //   var offence_status_HP =
  //     props.selectedUnitAttackerData.inputHealth - defenceResult;

  //   var defenceResult = Math.round(
  //     (defenceForce / totalDamage) *
  //       props.selectedUnitDefenderData.defence *
  //       4.5
  //   );
  // } else {
  //   var offence_status_HP = props.selectedUnitAttackerData.inputHealth - 0;
  //   var defenceResult = 0;
  // }

  // original

  var offence_status_HP =
    props.selectedUnitAttackerData.inputHealth - defenceResult;
  var defence_status_HP =
    props.selectedUnitDefenderData.inputHealth - attackResult;

  var offenceStatus = {
    Survived: true,
    Title: "Survived",
    value: props.offence_status_HP,
  };

  var defenceStatus = {
    Survived: true,
    Title: "Survived",
    value: props.defence_status_HP,
  };

  if (offence_status_HP > 0) {
    offenceStatus.Survived = true;
  } else {
    offenceStatus.Survived = false;
    offenceStatus.Title = "Defeated";
  }

  if (defence_status_HP > 0) {
    defenceStatus.Title = "Survived";
  } else {
    defenceStatus.Title = "Defeated";
    defenceStatus.Survived = false;
  }

  function MobileTable() {
    return (
      <div className="">
        <div className="border border-zinc-900 dark:border-zinc-200  mb-10">
          <div className="grid grid-cols-12">
            <div className="text-2xl col-span-12 justify-self-start mx-2">
              Offense
            </div>
            <img
              src={imageAttacker}
              width={75}
              height={75}
              className="rounded-xl col-span-12 justify-self-center"
              alt="attacker"
            />
            <div className="">
              <span
                className={`hide-text ${
                  props.selectedUnitAttackerData.isBoosted
                    ? "show-text-green"
                    : ""
                }`}
              >
                ({boostedDamageToUse})
              </span>
            </div>
          </div>
          <div>
            <div className="grid grid-rows-2 grid-flow-col ">
              <div>Attack:{props.selectedUnitAttackerData.attack} </div>
              <div>Defense:{props.selectedUnitAttackerData.defence}</div>
              <div>
                Original HP {props.selectedUnitAttackerData.inputHealth}
                <span style={{ color: "green" }}>
                  {props.selectedUnitAttackerData.isVeteran
                    ? ` +${veteranBuff}`
                    : ""}
                </span>
              </div>
              <div className="damageTaken">-({defenceResult})</div>
            </div>
          </div>

          <div className="py-5">
            Status:
            <span
              className={`p-0 m-0 show-text pr-5 ${
                offenceStatus.Title === "Defeated" ? "show-text-red" : ""
              }`}
            >
              {offenceStatus.Title}
            </span>
          </div>
        </div>
        <div className="border border-zinc-900 dark:border-zinc-200">
          <div className="grid grid-cols-12">
            <div className="text-2xl col-span-12 justify-self-start mx-2">
              Defense
            </div>
            <img
              src={imageDefender}
              width={75}
              height={75}
              className="rounded-xl col-span-12 justify-self-center"
              alt="attacker"
            />
            <div>
              <span
                className={`hide-text ${
                  props.selectedUnitDefenderData.isBoosted
                    ? "show-text-green"
                    : ""
                }`}
              >
                ({boostedDamageToUse})
              </span>
            </div>
          </div>
          <div>
            <div className="grid grid-rows-2 grid-flow-col">
              <div>Attack:{props.selectedUnitDefenderData.attack} </div>
              <div>Defense:{props.selectedUnitDefenderData.defence}</div>
              <div>
                Original HP {props.selectedUnitDefenderData.inputHealth}
                <span style={{ color: "green" }}>
                  {props.selectedUnitDefenderData.isVeteran
                    ? ` +${veteranBuff}`
                    : ""}
                </span>
              </div>
              <div className="damageTaken">-({attackResult})</div>
            </div>
          </div>
          <div></div>
          <div className="py-5">
            Status:
            <span
              className={`p-0 m-0 show-text pr-5 ${
                defenceStatus.Title === "Defeated" ? "show-text-red" : ""
              }`}
            >
              {defenceStatus.Title}
            </span>
          </div>
        </div>
      </div>
    );
  }

  function DesktopTable() {
    return (
      <div className="w-fit border-2 border-zinc-900 dark:border-zinc-200  shadow-xl mx-auto ">
        <div className="card-body items-center text-center">
          <h2 className="card-title text-2xl dark:text-slate-200 text-slate-700 font-normal">
            <img
              className="small-image-size"
              src="/misc-images/BigSwords.png"
              alt="swords"
            />
            Fight Results
            <img
              className="small-image-size"
              src="/misc-images/BigSwords.png"
              alt="swords"
            />
          </h2>
        </div>

        <div className="w-max mx-20">
          <table className="table">
            {/* head */}
            <thead>
              <tr className="text-xl dark:text-slate-200 text-slate-700 font-bold">
                <th></th>
                <th>Offense</th>
                <th>Health</th>
                <th>Attack</th>
                <th>Defense</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-10">
                  <img
                    src={imageAttacker}
                    width={50}
                    height={50}
                    className="rounded-xl"
                    alt="polytopia character"
                  />
                  <span className="damageTaken">(-{defenceResult})</span>
                </td>
                <th className="dark:text-slate-200 text-slate-700 font-normal">
                  {props.selectedUnitAttackerData.name}
                </th>

                <td style={{ borderBottom: "none" }} className="p-0 dark:text-slate-200 text-slate-700 font-normal">
                  Original HP: {props.selectedUnitAttackerData.inputHealth}
                </td>

                <td className="dark:text-slate-200 text-slate-700 font-normal">Attack: {props.selectedUnitAttackerData.attack}</td>
                <td className="dark:text-slate-200 text-slate-700 font-normal">Defense: {props.selectedUnitAttackerData.defence}</td>

                <td className="flex items-center h-28">
                  <div
                    className={`p-0 m-0 show-text pr-5 dark:text-slate-200 text-slate-700 font-normal ${
                      offenceStatus.Title === "Defeated" ? "show-text-red" : ""
                    }`}
                  >
                    {offenceStatus.Title}
                  </div>
                  <div className="dark:text-slate-200 text-slate-700 font-normal">
                    {props.selectedUnitAttackerData.inputHealth - defenceResult}
                  </div>
                  <div
                    className={`ml-5 small-image-size dark:text-slate-200 text-slate-700 font-normal ${
                      offenceStatus.Survived ? "hide-image" : ""
                    }`}
                  >
                    <img src="/misc-images/Vengir_Skull.png" alt="skulls" />
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <span style={{ color: "green" }}>
                    {props.selectedUnitAttackerData.isVeteran
                      ? ` +${veteranBuff}`
                      : ""}
                  </span>
                </td>
                <td>
                  <span
                    className={`hide-text ${
                      props.selectedUnitAttackerData.isBoosted
                        ? "show-text"
                        : ""
                    }`}
                  >
                    ({boostedDamageToUse})
                  </span>
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="w-max mx-20">
          <table className="table">
            {/* head */}
            <thead>
              <tr className="text-xl dark:text-slate-200 text-slate-700 font-bold">
                <th></th>
                <th>Defense</th>
                <th>Health</th>
                <th>Attack</th>
                <th>Defense</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {/* row 1 */}
              <tr>
                <td className="px-10">
                  <img
                    src={imageDefender}
                    width={50}
                    height={50}
                    className="rounded-xl"
                    alt="polytopia character"
                  />
                  <span className="damageTaken">(-{attackResult})</span>
                </td>
                <th className="dark:text-slate-200 text-slate-700 font-normal">
                  {props.selectedUnitAttackerData.name}
                </th>

                <td style={{ borderBottom: "none" }} className="p-0 dark:text-slate-200 text-slate-700 font-normal">
                  Original HP: {props.selectedUnitDefenderData.inputHealth}
                </td>

                <td className="dark:text-slate-200 text-slate-700 font-normal">Attack: {props.selectedUnitDefenderData.attack}</td>
                <td className="dark:text-slate-200 text-slate-700 font-normal">Defense: {props.selectedUnitDefenderData.defence}</td>

                <td className="flex items-center h-28">
                  <div
                    className={`p-0 m-0 show-text pr-5 ${
                      defenceStatus.Title === "Defeated" ? "show-text-red" : ""
                    }`}
                  >
                    {defenceStatus.Title}
                  </div>
                  <div className="dark:text-slate-200 text-slate-700 font-normal">
                    {props.selectedUnitDefenderData.inputHealth - attackResult}
                  </div>
                  <div
                    className={`ml-5 small-image-size ${
                      defenceStatus.Survived ? "hide-image" : ""
                    }`}
                  >
                    <img src="/misc-images/Vengir_Skull.png" alt="skulls" />
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>

                  <span style={{ color: "green" }}>
                    {props.selectedUnitDefenderData.isVeteran
                      ? ` +${veteranBuff}`
                      : ""}
                  </span>
                </td>
                <td>
                  <span
                    className={`hide-text ${
                      props.selectedUnitAttackerData.isBoosted
                        ? "show-text"
                        : ""
                    }`}
                  >
                    ({boostedDamageToUse})
                  </span>
                </td>
                <td>
                  {" "}
                  <span
                    className={`hide-text ${
                      props.selectedUnitDefenderData.defence_Bonus
                        ? "show-text"
                        : props.selectedUnitDefenderData.isCityWall
                        ? "show-text"
                        : ""
                    }`}
                  >
                    {" "}
                    (* {defenseBonus})
                  </span>
                  <span
                    className={`hide-text ${
                      props.selectedUnitDefenderData.isPoisoned
                        ? "show-text-red"
                        : ""
                    }`}
                  >
                    ({props.selectedUnitDefenderData.default_Bonus})
                  </span>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* <div className="grid grid-cols-9 gap-1 items-center">
          <div>Offense</div>
          <div>({offence_status_HP})</div>
          <div className="gap-0 justify-self-end damageTaken">
            (-{defenceResult})
          </div>
          <div>
            <img
              src={imageAttacker}
              width={50}
              height={50}
              className="rounded-xl"
              alt="polytopia character"
            />
          </div>
          <div>
            Attack:{props.selectedUnitAttackerData.attack}
            <span
              className={`hide-text ${
                props.selectedUnitAttackerData.isBoosted ? "show-text" : ""
              }`}
            >
              ({boostedDamageToUse})
            </span>
          </div>
          <div>Defense: {props.selectedUnitAttackerData.defence}</div>
          <div>
            Original HP {props.selectedUnitAttackerData.inputHealth} (max:
            {attackerMaxHPToUse})
            <span style={{ color: "green" }}>
              {props.selectedUnitAttackerData.isVeteran
                ? ` +${veteranBuff}`
                : ""}
            </span>
          </div>
          <div>
            Status:
            <span
              className={`show-text ${
                offenceStatus.Title === "Defeated" ? "show-text-red" : ""
              }`}
            >
              {offenceStatus.Title}
            </span>
          </div>
          <div
            className={` small-image-size ${
              offenceStatus.Survived ? "hide-image" : ""
            }`}
          >
            <img src="/misc-images/Vengir_Skull.png" alt="skulls" />
          </div>
          <div>Defense</div>
          <div>({defence_status_HP})</div>
          <div className="gap-0 justify-self-end damageTaken">
            (-{attackResult})
          </div>
          <div>
            {<img src={imageDefender} width={50} alt="selected defender" />}
          </div>
          <div>Attack:{props.selectedUnitDefenderData.attack}</div>
          <div>
            Defense: {props.selectedUnitDefenderData.defence}
            <span
              className={`hide-text ${
                props.selectedUnitDefenderData.defence_Bonus
                  ? "show-text"
                  : props.selectedUnitDefenderData.isCityWall
                  ? "show-text"
                  : ""
              }`}
            >
              {" "}
              (* {defenseBonus})
            </span>
            <span
              className={`hide-text ${
                props.selectedUnitDefenderData.isPoisoned ? "show-text-red" : ""
              }`}
            >
              {" "}
              ({props.selectedUnitDefenderData.default_Bonus})
            </span>
          </div>
          <div>
            Original HP {props.selectedUnitDefenderData.inputHealth} (max:
            {defenderMaxHPToUse}){" "}
            <span style={{ color: "green" }}>
              {props.selectedUnitDefenderData.isVeteran
                ? ` +${veteranBuff}`
                : ""}{" "}
            </span>{" "}
          </div>
          <div>
            Status:
            <span
              className={`show-text ${
                defenceStatus.Title === "Defeated" ? "show-text-red" : ""
              }`}
            >
              {" "}
              {defenceStatus.Title}
            </span>
          </div>
          <div
            className={` small-image-size ${
              defenceStatus.Survived ? "hide-image" : ""
            }`}
          >
            <img
              className="small-image-size"
              src="/misc-images/Vengir_Skull.png"
              alt="skull"
            />
          </div>
        </div> */}
      </div>
    );
  }

  return (
    <div className="flex flex-col  mx-5 gap-5 pt-10">
      <div>
        {isDesktop ? <div>{DesktopTable()}</div> : <div>{MobileTable()}</div>}
      </div>
      <div className="card-actions justify-end">
        <button
          className="btn btn-primary mb-5 text-slate-100 font-normal"
          onClick={() => resetButton()}
        >
          Reset Battle
        </button>
      </div>
    </div>
  );
}
// className={`hide-text ${props.selectedUnitAttackerData.isBoosted ? 'show-text' : ''}`};
