// import Modal from './Modal';
// import React, {useState} from 'react'


export default function UnitCard(props) {

  // console.log(props.selectedUnitData, props.isAttacker, props.isBoosted, props.inputHealth, props.isVeteran);

  let imageSource = `${process.env.PUBLIC_URL}/Defender-images/${props.selectedUnitData.imageSource}`;
  if (props.isAttacker) {
    imageSource = `${process.env.PUBLIC_URL}/Attacker-images/${props.selectedUnitData.imageSource}`;
  }

  let cardTitle = "Defender";
  if (props.isAttacker) {
    cardTitle = "Attacker";
  }


  // show/hide defense bonuses

    // const [showDefenseBonuses, setShowDefenseBonuses] = useState(true);

  // function handleDefenseBonus(defenseBonusValue){
  //   this.setState({})
  // }

  function updateInput(event, valueName) {
    const newSelectedUnitData = {...props.selectedUnitData};
    if(valueName === "inputHealth"){

      newSelectedUnitData[valueName] = parseInt(event.target.value);
    }

    else{
      newSelectedUnitData[valueName] = event.target.checked;
    }

    // console.log(event.target.value + "event-target-value");
    // console.log(event.target.value.checked + "checkbox prop");

    props.updateData(props.isAttacker, newSelectedUnitData);

  }





  return (
    <div className="card w-96 bg-base-100 shadow-xl">
      <div className="p-3">
        <h2 className="card-title text-2xl dark:text-slate-200 text-slate-700 font-bold">{cardTitle}</h2>
      </div>

      <figure className="px-10 pt-10">
        <img src={imageSource} width={150} className="rounded-xl" />
      </figure>

      <div className="card-body items-left text-center flex flex-col justify-between">
        <div className="unitcard-inpu ">
          <input
            value={props.selectedUnitData.inputHealth}
            onChange={(event) => updateInput(event, 'inputHealth')}
            type="number"
            placeholder="Enter Input Health"
            className= {`input  w-full max-w-xs dark:text-slate-200 text-slate-700 font-normal ${props.isAttacker ? ' input-error' : ' input-info'} `}

          />

          {/* 00000000 */}


          <div className="form-control">
            <label className="label cursor-pointer">
              <span className="label-text dark:text-slate-200 text-slate-700 font-normal ">Is veteran?</span>
              <input
                type="checkbox"
                checked={props.isVeteran}
                onClick={(event) => updateInput(event, 'isVeteran')}
                className={`checkbox checkbox-info  ${props.isAttacker ? 'border-red-500 checkbox-error' : 'border-blue-500 checkbox-info'}`}
              />
            </label>
          </div>
          {props.isAttacker && (
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text dark:text-slate-200 text-slate-700 font-normal">Is it boosted?</span>
                <input
                  type="checkbox"
                  checked={props.isBoosted}
                  onClick={(event) => updateInput(event, 'isBoosted')}
                  className="border-red-500 checkbox checkbox-error"
                />
              </label>
            </div>
          )}



          {!props.isAttacker && (
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text dark:text-slate-200 text-slate-700 font-normal">Is it poisioned?</span>
                <input
                  type="checkbox"
                  // checked={props.isPoisoned}
                  onClick={(event) => {
                    updateInput(event, 'isPoisoned')
                  }}

                  className="border-blue-500 checkbox checkbox-info"
                />
              </label>
            </div>
          )}
          {!props.isAttacker &&(

            <div>

              <div className="form-control">
            <label className="label cursor-pointer">
            <span className="label-text dark:text-slate-200 text-slate-700 font-normal">Defense Bonus?</span>
                <input
                  type="checkbox"
                  checked={props.defence_Bonus}
                  onClick={(event) => updateInput(event, 'defence_Bonus')
                  }
                  className="checkbox border-blue-500 checkbox-info"

                />
              </label>
            </div>

            </div>

          )}
          {!props.isAttacker && (
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text dark:text-slate-200 text-slate-700 font-normal">City wall?</span>
                <input
                  type="checkbox"
                  checked={props.cityWall}
                  onClick={(event) => updateInput(event, 'isCityWall')}
                  className="checkbox border-blue-500 checkbox-info"
                />
              </label>
            </div>
          )}

        </div>
        <div className="unitcard-button">
          <div className="card-actions">
            <button
              className={`btn  ${props.isAttacker ? 'btn-outline btn-error hover:bg-red-400' : 'btn-outline btn-info hover:bg-blue-400'} modal-button text-slate-100 font-normal`}
              onClick={() => props.triggerModal(props.isAttacker)}>Select Unit</button>
              {/* {`hide-text ${
                  props.selectedUnitAttackerData.isBoosted
                    ? "show-text-green"
                    : ""
                }`} */}
             {/* <label htmlFor="my-modal" className="btn modal-button" onClick={() => props.triggerModal(props.isAttacker)}>open modal</label>   */}

          </div>
        </div>
      </div>
    </div>
  );
}
